import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface sharedImages {
  index: number;
  allImages: string[];
  showImage: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ViewImagesService {
  targetedLang: string = 'en';
  private sharedImages = new BehaviorSubject<sharedImages>({
    index: 0,
    allImages: [],
    showImage: true
  });
  currentImages = this.sharedImages.asObservable();

  constructor() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.targetedLang = localStorage.getItem('targetedLang') || 'en';
    }
  }

  viewFullScreenImage(index: number, allImages: string[] , showImage: boolean) {
    // console.log('recieved data from parent in service: ',index, " ", allImages, " ", showImage);
    this.sharedImages.next({ index, allImages , showImage});
  }

}
