@if(openUploadWindow){
<div class="parent-container black-font flex-cent flex-col">
    <h3 class="heading">{{'upload.title' | translate}}</h3>
    <div class="upload flex-cent flex-col">
        <label class="selectFile">
            <input type="file" id="attachedFile" accept="image/*" multiple (change)="onFileSelected($event)">
        </label>

        @if(purpose == 'profile'){
            <p class="photocount">{{'upload.oneImage' | translate}}</p>
        }@else{
            <p class="photocount">{{'upload.youCanUpload' | translate}} {{photoCount | hindiNumber}} {{'upload.moreImages' | translate}}</p>
        }

        <div class="preview-container">
            <div class="photos flex-cent">
                @for(photo of photos ; track photo){
                <div class="preview">
                    <img [src]="photo" alt="">
                </div>
                }
            </div>
        </div>

    </div>
    <div class="buttons flex-cent">
        <button class="Btn" (click)="closeWindow()">{{'upload.cancel' | translate}}</button>
        <button class="Btn" (click)="uploadImages()">{{'upload.upload' | translate}}</button>      
    </div>

</div>
}