import { Pipe, PipeTransform } from '@angular/core';
import { escape } from 'querystring';

@Pipe({
  name: 'hindiNumber',
  standalone: true
})
export class HindiNumberPipe implements PipeTransform {

  targetedLang: string | null = 'en';

  private hindiNumbers = ['०', '१', '२', '३', '४', '५', '६', '७', '८', '९'];

  transform(value: number): any {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.targetedLang = localStorage.getItem("targetedLang") || null;
      if (this.targetedLang && this.targetedLang !== 'en') {
        return value.toString().split('').map(digit => this.hindiNumbers[+digit]).join('');

      } else {

        return value

      }
    } else {

      return value

    }
  }
}
