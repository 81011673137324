<app-navbar [navbarDisplay]="navbarDisplay"></app-navbar>

<div class="parent-container black-font flex-cent flex-col">
  <app-namesection [studentName]="studentName" [profilePhoto]="profilePhoto" [age]="age"></app-namesection>

  <h2 class="title">{{'progressTracker.progressTracker' | translate}}</h2>

  <div class="tracker-container black-font flex-cent flex-col">
    <!-- <img src="../../../../assets/img/temp_progress.png" alt=""> -->
    <h4 class="title">{{'progressTracker.overallReport' | translate}}</h4>

    <div class="chartArea">
      <div id="mainChart" class="chartdiv"></div>
    </div>
  </div>

  <div class="dropdown-container flex-cent flex-col">
    <div class="dropdown" (click)="toggleDropdown()">
        <!-- <p class="dropdown-default-text">{{categoryWise}} </p> -->
        <p class="dropdown-default-text">{{'progressTracker.SelectCategory' | translate}} </p>
      
      <div class="dropdown-arrow">
        <img src="../../../../assets/img/dropdown.png" alt="" [ngClass]="{'active': isCategoriesExpaneded}">
      </div>
    </div>

    @if (isCategoriesExpaneded) {
    <div class="expandabelDiv">
      @for (category of mainChartData; track category; let i = $index) {
      <div (click)="categoryTracker(category)" [ngClass]="'subCatexpand subCatexpand-' + i">
        <p>{{i+1+"."}} {{category.catName}}</p>
      </div>
      }
    </div>
    }
  </div>


  <!-- Category Progress Tracker -->
   <!-- @if(categoryWise !== 'Category Wise Tracker'){ -->
    <div class="tracker-container flex-cent flex-col">
      <!-- <img src="../../../../assets/img/temp_progress.png" alt=""> -->
       @if(categoryWise === 'Select Category'){
        <p class="chooseCategoryMsg">{{'progressTracker.choseCategoryMsg' | translate}}</p>
       }@else{
        <h4 class="title">{{categoryWise}}</h4>
       }
      
      <div class="chartArea">
        <div id="categoryChart" class="categoryChart"></div>
      </div>
    </div>
   <!-- } -->
  

</div>

<app-footer-navbar [class_id]="class_id"></app-footer-navbar>