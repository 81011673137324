  <section id="resultComponent">
    <app-navbar
      [teacherName]="teacherName"
      [class_id]="class_id"
      [schoolNavBar]="schoolNavbar"
      [standard]="standard"
      [division]="division"
      [totalStudents]="totalStudents"
      [goBackTo]="goBackTo"></app-navbar>
    <section
      id="catResult"
      class="w-100 d-flex justify-content-center align-items-center flex-column">
      <app-namesection
        [studentName]="studentName"
        [profilePhoto]="profilePhoto"
        [age]="age"></app-namesection>
      <div
        class="topSection black-font mt-2 mb-1 d-flex justify-content-around align-items-center">
        <div class="fw-medium fs-4">
          <span>{{ 'result.title' | translate }}</span>
        </div>
        <div class="averageResult d-flex align-items-center ps-3 pe-3">
          <span
            >{{ 'result.overallAvg' | translate
            }}<span class="professional-blue-font">
              {{ averageResult | hindiNumber }}%</span
            ></span
          >
        </div>
      </div>
      <div
        class="container d-flex justify-content-center align-items-center flex-wrap">
        @for (result of categoryResult; track result) {
          <div class="result-card professional-blue-font">
            <div
              class="categoryCard d-flex align-items-center flex-column"
              role="presentation"
              (click)="goToSubResult(result.name, result.id, result.result)">
              <label for="inputElement" class="catName ms-1 me-1">{{
                result.name
              }}</label>
              <div
                class="pieBox d-flex align-items-center justify-content-around w-100">
                <!-- Piechart div -->
                <!-- <div *ngIf="result.result > 0 "> -->
                <div id="{{ result.chartId }}" class="piechart"></div>
                <!-- </div> -->
                <!-- <div *ngIf="result.result == 0 " >
            <img class="incomplete_category_img" src="../../../../assets/img/incomplete_category.png" alt="Imgae error">
          </div> -->
                <div class="cat-percentage avgColor">
                  {{ result.result | hindiNumber }}%
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <div class="buttons flex-space-around ">
        <button class="backButton button-position" (click)="goPortFolio()">{{'parentShare.portfolio' | translate}}</button>
        <button class="backButton " (click)="goToTracker()">TRACKER</button>
      </div>

      <app-footer-navbar [class_id]="class_id"></app-footer-navbar>
    </section>
  </section>
