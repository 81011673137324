import { AfterViewInit, Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// compomenent Import
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { NamesectionComponent } from '../../../shared/component/nameSection/namesection.component';

// service Imports
import { ResultsService } from '../../../shared/services/api/results.service';
import { ToastService } from '../../../shared/services/alert/toast.service';
import { GraphService } from '../../../shared/services/utility/graph.service';

// interface Imports
import { params } from '../../../shared/interfaces/common';
import { Category } from '../../../shared/interfaces/common';

// pipe Imports
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../../shared/pipe/hindi-number.pipe';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';

@Component({
  selector: 'app-result',
  standalone: true,
  imports: [
    NavbarComponent,
    FooterNavbarComponent,
    NamesectionComponent,
    SpinnerComponent,
    TranslateModule,
    HindiNumberPipe,
  ],
  providers: [ResultsService],
  templateUrl: './result.component.html',
  styleUrl: './result.component.css',
})
export class ResultComponent implements AfterViewInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private resultService = inject(ResultsService);
  private toastService = inject(ToastService);
  private translate = inject(TranslateService);
  private graphService = inject(GraphService);

  schoolName: string = 'Global International School';
  teacherName: string = '';
  standard: string = 'Nursery';
  division: string = 'A';
  totalStudents: number = 20;
  studentName: string = 'studentName';
  profilePhoto: string = '';
  age: string = '4 years';
  student_id: number = 0;
  class_id: number = 0;
  schoolNavbar: boolean = true; // if true show navbar otherwise don't
  dob_date: number = 0;
  dob_month: number = 0;
  dob_year: number = 0;
  goBackTo: string = 'classroom';

  //component Variables
  averageResult: number = 0; //average of all 6 categories
  pieColor: number = 0xcecece; // hexadecimal code for gray color by default
  params: any;
  loader: boolean = true;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    this.student_id = Number(this.route.snapshot.paramMap.get('student_id'))!;
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'))!;
    this.params = this.route.snapshot.queryParams;
    console.log('recieved params in results : ', this.params);
    this.studentName = this.params.studentName;
    this.profilePhoto = this.params.profilePhoto;
    // console.log("profile photo recieved in results comp is : ",this.profilePhoto);
    this.standard = this.params.standard;
    this.division = this.params.division;
    this.totalStudents = Number(this.params.totalStudents);
    this.teacherName = this.params.teacherName;
    this.age = this.params.age;
    this.dob_date = Number(this.params.dob_date);
    this.dob_month = Number(this.params.dob_month);
    this.dob_year = Number(this.params.dob_year);
  }

  // preprod database categories

  categoryResult: Category[] = [
    {
      id: 15, // 15 for preprod , 8 for local
      name: this.translate.instant('Physical Development'),
      shortName: 'PD',
      chartId: 'chartdiv2',
      result: 0,
    },
    {
      id: 16, // 16 for preprod , 7 for local
      name: this.translate.instant('Communication and Language'),
      shortName: 'CL',
      chartId: 'chartdiv1',
      result: 0,
    },
    {
      id: 17, // 17 for preprod , 9 for local
      name: this.translate.instant('Socio-Emotional Development'),
      shortName: 'SD',
      chartId: 'chartdiv3',
      result: 0,
    },
    {
      id: 18, // 18 for preprod , 10 for local
      name: this.translate.instant('Problem Solving'),
      shortName: 'PS',
      chartId: 'chartdiv4',
      result: 0,
    },
    {
      id: 19, // 19 for preprod , 11 for local
      name: this.translate.instant('General Knowledge'),
      shortName: 'GK',
      chartId: 'chartdiv5',
      result: 0,
    },
    {
      id: 20, // 20 for preprod , 12 for local
      name: this.translate.instant('Arts and Creativity'),
      shortName: 'AC',
      chartId: 'chartdiv6',
      result: 0,
    },
  ];

  ngAfterViewInit() {
    this.getCompletedMilestones();
  }
  goToSubResult(cat_name: string, id: number, result: number): void {
    if (result !== 0) {
      this.router.navigate(
        ['/subcat-results', this.class_id, this.student_id, id],
        {
          queryParams: {
            cat_name: cat_name,
            studentName: this.studentName,
            age: this.age,
            profilePhoto: this.profilePhoto,
            schoolName: this.schoolName,
            totalStudents: this.totalStudents,
            standard: this.standard,
            division: this.division,
            teacherName: this.teacherName,
            dob_date: this.dob_date,
            dob_month: this.dob_month,
            dob_year: this.dob_year,
          },
        }
      );
    }
  }

  goPortFolio() {
    this.router.navigate(['/portfolio', this.class_id, this.student_id], {
      queryParams: {
        dob_date: this.dob_date,
        dob_month: this.dob_month,
        dob_year: this.dob_year,
        schoolName: this.schoolName,
        profilePhoto: this.profilePhoto,
        standard: this.standard,
        division: this.division,
        teacherName: this.teacherName,
      },
    });
  }

  goToTracker() {
    this.router.navigate(
      ['/progress-tracker', this.class_id, this.student_id],
      {
        queryParams: this.params,
      }
    );
  }

  // API call for getting completed milstones and thier results
  getCompletedMilestones() {
    let screen = 'result';
    this.resultService
      .getCompletedMilestones(
        this.class_id,
        this.student_id,
        this.dob_month,
        this.dob_date,
        this.dob_year,
        screen
      )
      .subscribe((data: any) => {
        // console.log("getting completed milestones : ",data);
        this.loader = false;
        if (data.data.length == 0) {
          window.history.back();
        }
        let avg: number = 0,
          avgSum: number = 0,
          completed_cat: number = 0;

        for (const obj of this.categoryResult) {
          const cat_id = obj.id;
          let sum: number = 0,
            no_of_subcat: number = 0,
            result: number = 0;

          for (const i of data.data) {
            if (cat_id == i.category_id) {
              sum += i.result;
              no_of_subcat++;
            }
          }

          // result = Math.round((sum / (no_of_subcat * 100)) * 100);
          result = Math.floor(
            Number(((sum / (no_of_subcat * 100)) * 100).toFixed(2))
          ); // considering upto 2 decimal places to avoid Floating-Point Precision Issues
          // console.log('result : ', result);

          if (isNaN(result)) result = 0;

          obj.result = result;

          // calculating average result of all attempted categories
          if (obj.result > 0) {
            avgSum += obj.result;
            completed_cat++;
          }

          avg = avgSum / completed_cat;
        }

        this.averageResult = Math.round(avg);

        if (this.averageResult == 0) {
          this.toastService.showAlert(
            this.translate.instant('result.emptyRemark'),
            this.translate.instant('Note')
          );
          window.history.back();
        }

        // finally creating peicharts
        let element = 0;
        for (const i of this.categoryResult) {
          // setting the color for result
          if (this.categoryResult[element].result <= 25)
            this.pieColor = 0xFBAF55; // light orange
          else if (
            this.categoryResult[element].result >= 26 &&
            this.categoryResult[element].result <= 65
          )
            this.pieColor = 0xFCF969; // yellow
          else if (
            this.categoryResult[element].result >= 66 &&
            this.categoryResult[element].result <= 100
          )
            this.pieColor = 0xA9FA68; // green

          this.graphService.createPieChart(i.chartId, i.result, this.pieColor);
          element++;
        }
      });
  }
}
