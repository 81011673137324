import { Component , Input} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-namesection',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './namesection.component.html',
  styleUrl: './namesection.component.css'
})
export class NamesectionComponent {

  @Input() studentName:string ="Student Name" ;
  @Input() age:string ="1 ";
  @Input() milestonePage : boolean = false;
  profileImageLink: string = 'https://bmc-space.blr1.digitaloceanspaces.com/'; 
  @Input() profilePhoto : string = '';

  constructor(){
    // console.log("recieved profile Photo in nameSection : ",this.profilePhoto);
  }
}
