import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../../shared/services/api/login.service';
import { AuthResponse } from '../../../shared/interfaces/api';
import { Store } from '@ngrx/store';
import { setTeacherObject } from '../../../../state/teacher/teacher.actions';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../shared/services/alert/alert-service.service';
@Component({
  selector: 'app-loginpage',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, TranslateModule],
  providers: [LoginService],
  templateUrl: './loginpage.component.html',
  styleUrl: './loginpage.component.css'
})
export class LoginpageComponent {
  private formBuilder = inject(FormBuilder);
  private router = inject(Router);
  private _loginServices = inject(LoginService);
  private store = inject(Store);
  private translate = inject(TranslateService);
  private alertService = inject(AlertService);

  isFormSubmitted = false;
  loginForm: FormGroup;
  isEmailValid: string | undefined;
  isPasswordValid: string | undefined;
  result: any;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    // normally we can't add more than one validators to a single field, so we use formbuilder.
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(7)])
    });
  }


  validateForm() {

    this.isFormSubmitted = true;
    const loginInfo = this.loginForm.value;
    this._loginServices.AuthenticateUser(loginInfo['email'], loginInfo['password']).subscribe({
      next: (res: AuthResponse) => {
        if (res.data.userdata.token !== null && res.data.userdata.token !== undefined) {
          this.store.dispatch(setTeacherObject({ teacherObject: res.data.userdata }));

          localStorage.setItem('token', res.data.userdata.token);
          localStorage.setItem('user', JSON.stringify(res.data.userdata));
          localStorage.setItem('isLoggedIn', "true");
          this.router.navigate(['/dashboard']);
        }
      },
      error: (error) => {
        this.result = error;

        this.alertService.showAlert(
          this.translate.instant('Note'),
          this.translate.instant('login.Remark'),
          'Note',
        );  
      }
    });
  }

  selectedLanguage: string | null = 'en';

  ngOnInit() {
    if (typeof window !== 'undefined' && window.localStorage) {
      const storedLanguage = localStorage.getItem('targetedLang');
      if (storedLanguage) {
        this.selectedLanguage = storedLanguage;
      }
    }
  }

  onLanguageChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    const language = target.value;

    this.selectedLanguage = language;
    this.translate.use(this.selectedLanguage);
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.setItem("targetedLang", this.selectedLanguage);
    }
  }

  validateEmail(): void {
    const loginInfo = this.loginForm.value;
    // Regular expression for email validation
    const emailRegex: RegExp = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

    // Test the email against the regex
    emailRegex.test(loginInfo['email']) ? this.isEmailValid = 'is-valid' : this.isEmailValid = 'is-invalid';

  }
  
  validatePassword(): void {
    const loginInfo = this.loginForm.value;

    loginInfo['password'].length > 7 ? this.isPasswordValid = 'is-valid' : this.isPasswordValid = 'is-invalid';

  }

  // toggle view password
  togglePassword() {
    const x = document.getElementById("userPassword") as HTMLInputElement;
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

}
