import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MilestoneService } from '../../../shared/services/api/milestone.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ResultsService } from '../../../shared/services/api/results.service';
import { GraphService } from '../../../shared/services/utility/graph.service';
import { NgClass } from '@angular/common';
import { CoreUtilityService } from '../service/core-utility.service';
import { HindiNumberPipe } from '../../../shared/pipe/hindi-number.pipe';
import { PortfolioPdfDownloadService } from '../../../shared/services/api/portfolio-pdf-download.service';

@Component({
  selector: 'app-portfolio',
  standalone: true,
  imports: [
    NgClass,
    NavbarComponent,
    SpinnerComponent,
    TranslateModule,
    HindiNumberPipe,
  ],
  templateUrl: './portfolio.component.html',
  styleUrl: './portfolio.component.css',
})
export class PortfolioComponent implements OnInit {
  standard: any;
  division: any;
  getRemarkDefault() {
    throw new Error('Method not implemented.');
  }
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private resultservice = inject(ResultsService);
  private translate = inject(TranslateService);
  private graphService = inject(GraphService);
  private coreUtility = inject(CoreUtilityService);
  private portfolioDownloadService = inject(PortfolioPdfDownloadService);

  schoolName: string = 'Global International School';
  loader: boolean = false;
  none: string = 'none';
  age: string = '3 y& 4 m';
  studentName: string = 'studentName';
  profilePhotoLink: string = 'https://bmc-space.blr1.digitaloceanspaces.com/';
  profilePhoto: string = '';
  class: string = 'Nursery A';
  teacherName: string = 'Suresh Raut';
  birthDate: string = '13/12/2002';
  parentNames: string = 'kartik';
  catname: string = 'Physical Development';
  params: any;
  dob_date: number = 10;
  dob_month: number = 5;
  dob_year: number = 2019;
  classId: number = 2;
  studentId: number = 3325;
  targetedLang: string = 'en';
  catData: any = [];
  subCatData: any = [];
  userData: any = [];
  height: number | string = '--';
  weight: number | string = '--';
  downloadInProcess: boolean = false;

  constructor(...args: unknown[]);
  constructor() {
    this.studentId = Number(this.route.snapshot.paramMap.get('student_id'))!;
    this.classId = Number(this.route.snapshot.paramMap.get('class_id'))!;
    console.log('class Id is : ', this.classId);

    this.params = this.route.snapshot.queryParams;
    console.log('params in porfolio frontend : ', this.params);

    this.studentName = this.params.studentName;
    this.standard = this.params.standard;
    this.division = this.params.division;
    this.teacherName = this.params.teacherName;
    this.profilePhoto = this.params.profilePhoto;
    console.log('profile photo in portfolio comp : ', this.profilePhoto);
    this.dob_date = Number(this.params.dob_date);
    this.dob_month = Number(this.params.dob_month);
    this.dob_year = Number(this.params.dob_year);
  }

  ngOnInit() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.targetedLang = localStorage.getItem('targetedLang') || 'en';
      this.resultservice
        .getPortFolio(
          this.classId,
          this.studentId,
          this.dob_month,
          this.dob_date,
          this.dob_year,
          this.targetedLang
        )
        .subscribe({
          next: (res: any) => {
            this.userData = res.data.user;
            console.log('userData : ', this.userData);
            this.catData = Object.keys(res.data.records).map(
              key => res.data.records[key]
            );
            this.loader = true;

            this.studentName = this.userData.child_last_name
              ? this.userData.child_name + ' ' + this.userData.child_last_name
              : this.userData.child_name;
            this.parentNames = this.userData.user_last_name
              ? this.userData.user_first_name +
                ' ' +
                this.userData.user_last_name
              : this.userData.user_first_name;
            this.birthDate = `${this.userData.child_born_date}/${this.userData.child_born_month}/${this.userData.child_born_year}`;

            this.age = this.coreUtility.calculateAge(
              Number(this.userData.child_born_year),
              Number(this.userData.child_born_month),
              Number(this.userData.child_born_date)
            );
            setTimeout(() => {
              for (let obj of this.catData) {
                this.loader = true;
                obj.cat_img =
                  'https://bmc-space.blr1.cdn.digitaloceanspaces.com/uploads/asscategoryimages/' +
                  `${obj.category_id}/${obj.cat_img}`;
                const elementId = obj.category_id.toString();
                const element = document.getElementById(elementId);

                if (element) {
                  let donutColor : number = 0;
                  // assigning color to the donut chart
                  if (obj.result >= 0 && obj.result <= 25) {
                    donutColor = 0xFBAF55; // light orange
                  } else if (obj.result >= 26 && obj.result <= 65) {
                    donutColor = 0xFCF969; // yellow
                  } else if (obj.result >= 66 && obj.result <= 100) {
                    donutColor = 0xA9FA68; // green
                  }
                  this.graphService.createDonutChart(elementId, obj.result , donutColor);
                } else {
                  console.error(
                    `Could not find HTML element with id '${elementId}'`
                  );
                }
              }
            });
          },
          error: error => {
            // Handle error
            console.error('Error:', error);
          },
        });
    }
  }

  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  downloadPortfolioPDF() {
    this.downloadInProcess = true;
    console.log('Teacher name: ', this.teacherName);
    this.portfolioDownloadService
      .downloadPortfolio(
        this.classId,
        this.studentId,
        this.dob_month,
        this.dob_date,
        this.dob_year,
        this.targetedLang
      )
      .subscribe({
        next: (res: Blob) => {
          console.log('Received PDF file:', res);

          const fileName = `${this.userData.child_name}-portfolio.pdf`;

          // Creating a URL for the Blob
          const url = window.URL.createObjectURL(res);

          // Creating a temporary link element so we can download pdf
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);

          this.downloadInProcess = false;
        },
        error: error => {
          console.error('Error:', error);
        },
      });
  }

  getReMark(result: number, subCatName: string) {
    if (result <= 26) {
      return `${this.studentName} ${this.translate.instant('result.lagging')} ${subCatName} ${this.translate.instant('result.development')} `;
    } else if (result >= 27 && result <= 66) {
      return `${this.studentName} ${this.translate.instant('result.encouraged')} ${subCatName} ${this.translate.instant('result.development')}`;
    } else if (result >= 67) {
      return `${this.studentName} ${this.translate.instant('result.onTrack')} ${subCatName} ${this.translate.instant('result.development')}`;
    } else {
      return '';
    }
  }

  getParse(imagObj: string) {
    return JSON.parse(imagObj);
  }

  getIconForStatus(status: string): string {
    switch (status) {
      case 'YES':
        return 'assets/img/buterfly.png';
      case 'SOMETIMES':
        return 'assets/img/caterpiller.png';
      case 'NO':
        return 'assets/img/pupa.png';
      default:
        return 'assets/img/pupa.png';
    }
  }
}
