import { RouterLink, RouterModule, Router } from '@angular/router';
import { Component, Input, OnInit, inject } from '@angular/core';

import { UserData } from '../../interfaces/api';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../pipe/hindi-number.pipe';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [RouterLink, RouterModule, TranslateModule , HindiNumberPipe],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css',
})
export class NavbarComponent implements OnInit {
  private router = inject(Router);
  private translate = inject(TranslateService);

  public schoolName: string = '';
  @Input() noBack: boolean = false;
  private schoolLogoUrl: string =
    'https://bmc-space.blr1.cdn.digitaloceanspaces.com/serviceProvider/';
  @Input() schoolLogo: string = '';

  @Input() teacherName: string = 'Mr. John Doe';
  @Input() navbarDisplay: string = 'show';
  @Input() schoolNavBar: boolean = true;
  @Input() standard: string = '';
  @Input() division: string = '';
  @Input() totalStudents: number = 0;
  @Input() class_id: number = 0;
  @Input() backToDash: boolean = false;
  @Input() backtoCwp: boolean = false;
  @Input() mode: string = '';

  @Input() goBackTo: string = '';
  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}
  ngOnInit() {
    // console.log("class id recieved in navbar component is : ",this.class_id);
    if (typeof window !== 'undefined' && window.localStorage) {
      const user = localStorage.getItem('user');
      if (user) {
        const logo: UserData = JSON.parse(user);
        this.schoolLogo = `${this.schoolLogoUrl}${logo.school_logo}`;
        this.schoolName = logo.school_Name;
      }
    }
  }

  goBack() {
    // if (this.class_id !== 0 && this.mode === '' && !this.backtoCwp) {
    //   // neccessary for navigating back from subcat screen to classroom screen
    //   this.router.navigate(['/class-room', this.class_id, this.teacherName]);
    // } else if (this.backToDash === true) {
    //   this.router.navigate(['/dashboard']);
    // } else if (this.mode === 'collated' && this.class_id !== 0) {
    //   // returning back to collated-report
    //   console.log(
    //     'mode recieved in navbar compo',
    //     this.mode,
    //     ' and class id is ; ',
    //     this.class_id
    //   );
    //   this.router.navigate(['summary-report', this.class_id, this.mode]);
    // } else if (this.mode === 'summary' && this.class_id !== 0) {
    //   // returning back to summary-report
    //   console.log(
    //     'mode recieved in navbar compo',
    //     this.mode,
    //     ' and class id is ; ',
    //     this.class_id
    //   );
    //   this.router.navigate(['summary-report', this.class_id, this.mode]);
    // } else if (this.backtoCwp === true && this.class_id !== 0) {
    //   this.router.navigate(['/connect-with-parents', this.class_id]);
    // } else {
    //   console.log('=========== window history back CALLED ===========');
    //   window.history.back();
    // }

    switch (this.goBackTo) {
      case 'classroom': {
        // console.log('going back to classroom with class_id : ',this.class_id, ' and teacherName: ',this.teacherName);
        this.router.navigate(['/class-room', this.class_id, this.teacherName]);
        break;
      }
      case 'dashboard': {
        // console.log("going back to dashboard");
        this.router.navigate(['/dashboard']);
        break;
      }
      case 'cwp': {
        // console.log("going back to connect-with-parents with class_id : ",this.class_id);
        this.router.navigate(['/connect-with-parents', this.class_id]);
        break;
      }
      case 'summary': {
        // console.log(
        //   'mode recieved in navbar compo',
        //   this.mode,
        //   ' and class id is ; ',
        //   this.class_id
        // );
        this.router.navigate(['summary-report', this.class_id, this.mode]);
        break;
      }
      case 'collated': {
        // console.log(
        //   'mode recieved in navbar compo',
        //   this.mode,
        //   ' and class id is ; ',
        //   this.class_id
        // );
        this.router.navigate(['summary-report', this.class_id, this.mode]);
        break;
      }
      case 'parent-share':{
        // console.log('going back to parent-share with class id : ',this.class_id);
        this.router.navigate(['/connect-with-parents' , this.class_id]);
        break;
      }

      default: {
        // console.log('=========== window history back CALLED ===========');
        window.history.back();
      }
    }
  }
}
