import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../../../config/config';

@Injectable({
  providedIn: 'root',
})
export class MilestoneService {
  private http = inject(HttpClient);
  private config = inject(ApplicationConfig);

  url: string = '';
  token: string | null = '';
  assessmentPeriodId : number | null = null;
  payload : Object = {};

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    const config = this.config;

    this.url = config.getAPIEnvironment();
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
      this.assessmentPeriodId = Number(localStorage.getItem('assessmentPeriodId')) || null;
    }

  }

  getMilestoneBySubCategory(
    cat_id: number,
    subcat_id: number,
    class_id: number,
    student_id: number,
    dob_month: number,
    dob_day: number,
    dob_year: number,
    targetedLang : string
  ): Observable<object> {
   
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    this.payload = {
      cat_id: cat_id,
      subcat_id: subcat_id,
      class_id: class_id,
      student_id: student_id,
      dob_month: dob_month,
      dob_day: dob_day,
      dob_year: dob_year,
      assessmentPeriodId : this.assessmentPeriodId,
      targetedLang : targetedLang
    };

    return this.http.post<any>(
      `${this.url}/milestone/milestones-sub-category`,
      this.payload,
      { headers }
    );
  }

  getMilestoneByCategory(
    cat_id: number,
    class_id: number,
    student_id: number,
    dob_month: number,
    dob_day: number,
    dob_year: number,
    targetedLang : string
  ): Observable<object> {

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    this.payload = {
      cat_id: cat_id,
      class_id: class_id,
      student_id: student_id,
      dob_month: dob_month,
      dob_day: dob_day,
      dob_year: dob_year,
      assessmentPeriodId : this.assessmentPeriodId,
      targetedLang : targetedLang
    };

    return this.http.post<any>(
      `${this.url}/milestone/milestones-category`,
      this.payload,
      { headers }
    );
  }

  saveMilestone(
    cat_id: number,
    subcat_id: number,
    class_id: number,
    student_id: number,
    school_id: number,
    milestone_ids: string,
    result: number,
    remark: string,
    dob_month : number,
    dob_day : number,
    dob_year : number
  ): Observable<object> {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
    }

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    this.payload = {
      cat_id: cat_id,
      subcat_id: subcat_id,
      class_id: class_id,
      student_id: student_id,
      school_id: school_id,
      milestone_ids: milestone_ids,
      result: result,
      remark: remark,
      dob_month : `${dob_month}`,
      dob_day :`${dob_day}`,
      dob_year :`${dob_year}`,
      assessmentPeriodId : this.assessmentPeriodId,
    };

    return this.http.post<any>(`${this.url}/milestone/save-milestone`, this.payload, {
      headers,
    });
  }

  getSavedMilestone(
    subcat_id: number,
    cat_id: number,
    class_id: number,
    student_id: number
  ): Observable<object> {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
    }

    const reqHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    return this.http.get<any>(
      `${this.url}/milestone/get-saved-milestone/${subcat_id}/${cat_id}/${class_id}/${student_id}`,
      { headers: reqHeaders }
    );
  }

  updateSavedMilestone(
    newMilestone: string,
    newScore: number,
    recordId: number,
    remark: string
  ): Observable<object> {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
    }

    const reqHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    this.payload = {
      newMilestone : newMilestone,
      newScore : newScore,
      remark : remark,
      recordId : recordId
    }


    return this.http.put<any>(
      `${this.url}/milestone/update-saved-milestone`,
      this.payload,
      { headers: reqHeaders }
    );
  }

  // api's for saving photos

  // api for getting presigned url for each image
  getPresignedUrl(
    images: Array<{ fileName: string, imageType: string }>, // here images means fileNames of the images and not actual images
    catID: number,
    subCatID: number,
    studentID: number,
    dobDate: number,
    dobMonth: number,
    dobYear: number,
    purpose: string
  ): Observable<object> {
    

    const reqHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    this.payload = {
      purpose : purpose,
      images: images,
      catID: catID,
      subCatID: subCatID,
      studentID: studentID,
      dobDate: dobDate,
      dobMonth: dobMonth,
      dobYear: dobYear,
      assessmentPeriodId : this.assessmentPeriodId
    };
    
    return this.http.post<any>(
      `${this.url}/image/presigned/`,
      this.payload,
      { headers: reqHeaders }
    );
  }


  // this api is not in use. 
  // uploadImages(presignedUrl: string, image: any): Observable<object> {
  //   let file = new File([image], 'file1.jpeg');
  //   console.log('file', file);

  //   const reqHeaders: HttpHeaders = new HttpHeaders({
  //     'Content-Type': 'image/jpeg',
  //   });

  //   return this.http.put<any>(presignedUrl, file, { headers: reqHeaders });
  // }


  // api for updating the database once images are uploaded to server.
  insertImagePath(
    studentID: number,
    catID: number,
    subcatID: number,
    imagesPath: Array<string>,
    dobDate: number,
    dobMonth: number,
    dobYear: number,
    purpose: string
  ): Observable<object> {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
    }

    const reqHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    this.payload = {
      purpose : purpose,
      studentID: studentID,
      catID: catID,
      subcatID: subcatID,
      imagesPath: imagesPath,
      dobDate: dobDate,
      dobMonth: dobMonth,
      dobYear: dobYear,
      assessmentPeriodId : this.assessmentPeriodId,
    };

    return this.http.post<any>(
      `${this.url}/image/insert-image-path/`,
      this.payload,
      { headers: reqHeaders }
    );
  }

  fetchImages(
    childId: number,
    catId: number, 
    subcatId: number,
    dob_date: number,
    dob_month: number, 
    dob_year: number
  ):Observable<object>{
    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
    }

    const reqHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    });

    return this.http.get<any>(
      `${this.url}/image/saved-images/${childId}/${catId}/${subcatId}/${this.assessmentPeriodId}/${dob_date}/${dob_month}/${dob_year}`,
      { headers: reqHeaders }
    );

  }
}
