@if (message) {
  <div aria-live="polite" aria-atomic="true" class="toast" [ngClass]="{'slide-in-bottom': message}">
    <div class="toast-header professional-blue-font">
      <img src="../../../../assets/img/loader.png" class="" alt="...">
    </div>
    <div class="toast-body professional-blue-font">
      <span>{{ message | translate}}</span>
    </div>
  </div>
}
