import { Injectable, inject } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { ApplicationConfig } from '../../../config/config';
import { ToastService } from '../alert/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class ResultsService {
  private http = inject(HttpClient);
  private toastService = inject(ToastService);
  private config = inject(ApplicationConfig);
  private translate = inject(TranslateService);
  private router = inject(Router);

  url: string = 'http://localhost:8080';
  token: string | null = '';
  reuestHeader: HttpHeaders = new HttpHeaders();
  assessmentPeriodId : Number | null = null;
  payload : object = {};

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {
    const config = this.config;

    this.url = config.getAPIEnvironment();
  }

  setReqHeader() {
    let reqHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
      if (this.token) {
        reqHeaders = reqHeaders.set('Authorization', 'Bearer ' + this.token);
        this.assessmentPeriodId = Number(localStorage.getItem('assessmentPeriodId')) || null;
      }
    }

    return reqHeaders;
  }

  getSubcatResult(
    studentId: number,
    classId: number,
    catId: number,
    dobMonth: number,
    dobDate: number,
    dobYear: number,
    targetedLang: string
  ): Observable<object> {
    this.reuestHeader = this.setReqHeader();

    this.payload = {
      studentId: studentId,
      classId: classId,
      catId: catId,
      targetedLang: targetedLang,
      dobMonth: dobMonth,
      dobDate: dobDate,
      dobYear: dobYear,
      assessmentPeriodId : this.assessmentPeriodId
    };

    return this.http.post<any>(`${this.url}/result/subcat-result`, this.payload, {
      headers: this.reuestHeader,
    });
  }

  // API for getting completed milstones and thier results

  getCompletedMilestones(
    classId: number,
    studentId: number,
    dobMonth: number,
    dobDay: number,
    dobYear: number,
    screen: string
  ): Observable<object> {
    this.reuestHeader = this.setReqHeader();

    return this.http
      .get<any>(
        `${this.url}/result/completed-milestones/${classId}/${studentId}/${dobMonth}/${dobDay}/${dobYear}/${this.assessmentPeriodId}`,
        { headers: this.reuestHeader }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404 && screen === 'result') {
            if (typeof window !== 'undefined') {
              this.toastService.showAlert(
                this.translate.instant('result.emptyRemark'),
                'Note'
              );
              window.history.back();
            }
            return of([]);
          } else if (error.status === 404 && screen === 'summary-report') {
            this.toastService.showAlert(
              this.translate.instant('result.emptyRemark'),
              'Note'
            );
            this.router.navigate(['/connect-with-parents', classId]);
            return of([]);
          } else {
            return of([]);
          }
        })
      );
  }

  // for piechart on the dashboard
  getClassOverallResult(class_id: number): Observable<object> {
    this.reuestHeader = this.setReqHeader();

    return this.http.get<any>(
      `${this.url}/result/get_class_overall_result/${class_id}`,
      { headers: this.reuestHeader }
    );
  }

  getPortFolio(
    classId: number,
    childID: number,
    dobMonth: number,
    dobDay: number,
    dobYear: number,
    targetedLang: string
  ): Observable<object> {
    this.reuestHeader = this.setReqHeader();

    return this.http.get<any>(
      `${this.url}/result/portfolio/${classId}/${childID}/${dobMonth}/${dobDay}/${dobYear}/${targetedLang}/${this.assessmentPeriodId}`,
      { headers: this.reuestHeader }
    );
  }
}
