import { Component, inject, Input, EventEmitter, Output } from '@angular/core';
import { MilestoneService } from '../../services/api/milestone.service';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../pipe/hindi-number.pipe';
import { ToastService } from '../../services/alert/toast.service';

@Component({
  selector: 'app-upload-photos',
  standalone: true,
  imports: [TranslateModule, HindiNumberPipe],
  providers: [MilestoneService],
  templateUrl: './upload-photos.component.html',
  styleUrl: './upload-photos.component.css',
})
export class UploadPhotosComponent {
  private translate = inject(TranslateService);
  private milestoneService = inject(MilestoneService);
  private toastService = inject(ToastService);

  @Input() openUploadWindow: boolean = true;
  @Input() purpose: string = '';
  @Input() maxPhotoCount: number = 0;
  @Input() studentName: string = 'demo name';
  @Input() subcat_name: string = 'demo_subcat';
  @Input() subcatId: number = 0;
  @Input() catId: number = 0;
  @Input() studentId: number = 0;
  @Input() dob_date: number = 0;
  @Input() dob_month: number = 0;
  @Input() dob_year: number = 0;
  @Output() closeUploadWindow = new EventEmitter<boolean>();

  photoCount: number = 0;

  photos: string[] = []; // To store preview URLs
  selectedFiles: File[] = []; // To store the actual files
  finalPhotos: File[] = []; // for finally upload photos with customized names.

  data: any;

  constructor() {
    this.openUploadWindow = true;
    // console.log('child is subscribing in constructor.');
  }

  ngOnInit(): void {
    // console.log(
    //   'child is in onInit',
    //   this.openUploadWindow,
    //   ' ',
    //   this.maxPhotoCount
    // );
    this.photoCount = this.maxPhotoCount;

    // console.log(
    //   'recieved parameters from milestones to uplaod throught @Input are following '
    // );

    // console.log('maxPhotoCount: ', this.maxPhotoCount);
    // console.log('studentName : ', this.studentName);
    // console.log('subcat_name: ', this.subcat_name);
    // console.log('catId: ', this.catId);
    // console.log('subcatId: ', this.subcatId);
    // console.log('studentId: ', this.studentId);
    // console.log('dob_date: ', this.dob_date);
    // console.log('dob_month: ', this.dob_month);
    // console.log('dob_year: ', this.dob_year);
    // console.log('purpose :', this.purpose);
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input.files) {
      // Limit to 5 photos
      // console.log('max photoCount received:', this.maxPhotoCount);
      const newFilesCount = input.files.length;

      // testing
      // console.log('files before compressing : ', input.files);

      if (this.photos.length + newFilesCount > this.maxPhotoCount) {
        this.toastService.showAlert(
          this.translate.instant('milestone.profilePhotoLimit'),
          this.translate.instant('Note')
        );
        return;
      }

      const filesArray = Array.from(input.files).slice(
        0,
        this.maxPhotoCount - this.photos.length
      );

      filesArray.forEach(async file => {
        try {
          // Compress the image using the Canvas API
          const compressedFile = await this.compressImage(file, 480, 640, 0.5); // Adjust maxWidth, maxHeight, and quality as needed

          // Convert compressed image to base64 for preview
          const reader = new FileReader();
          reader.onload = e => {
            // Add the compressed image to `photos` for preview
            this.photos.push(e.target?.result as string);
            this.photoCount--;
            // console.log('this.photos:', this.photos);
            // console.log('photoCount:', this.photoCount);
          };
          reader.readAsDataURL(compressedFile);

          // Add the compressed file to the selectedFiles array
          this.selectedFiles.push(compressedFile);
          // console.log('Compressed files in selectedFiles:', this.selectedFiles);

          // renaming the file
          const originalFile = compressedFile; // file means photo.
          let count = 5 - this.photoCount + 1;
          let customFileName = '';
          // removing spaces from names
          this.studentName = this.studentName.replace(/ /g, '_'); // removing space
          this.subcat_name = this.subcat_name.replace(/ /g, '_'); // removing space
          if (this.purpose === 'profile') {
            customFileName = `${this.studentName}${this.getFileExtension(originalFile.name)}`;
          } else {
            // name for subcatImages
            customFileName = `${this.studentName}_${this.subcat_name}_${count}${this.getFileExtension(originalFile.name)}`;
          }

          // console.log('customFileName : ',customFileName);

          // Create a new File object with the same content but a new name
          const newFile = new File([originalFile], customFileName, {
            type: originalFile.type,
          });

          this.finalPhotos.push(newFile);
          // console.log('this.finalPhotos array is : ',this.finalPhotos);
        } catch (error) {
          console.error('Error compressing the image:', error);
        }
      });
    }
  }

  compressImage(
    file: File,
    maxWidth: number,
    maxHeight: number,
    quality: number
  ): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d')!;

          // Maintain aspect ratio
          let width = img.width;
          let height = img.height;

          if (width > maxWidth || height > maxHeight) {
            if (width > height) {
              height = Math.round((maxWidth / width) * height);
              width = maxWidth;
            } else {
              width = Math.round((maxHeight / height) * width);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          // Draw the resized image
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas content to a Blob
          canvas.toBlob(
            blob => {
              if (blob) {
                // Create a new File object
                const compressedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                resolve(compressedFile);
              } else {
                reject('Image compression failed.');
              }
            },
            file.type,
            quality // Quality: 0.1 to 1.0
          );
        };

        img.src = event.target.result;
      };

      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  closeWindow() {
    // emptying the data
    this.photos = [];
    this.selectedFiles = [];
    this.finalPhotos = [];

    this.openUploadWindow = !this.openUploadWindow; // closing window.
    this.closeUploadWindow.emit(this.openUploadWindow); // sending boolean value to milestone saying that window is closed.

    // console.log('flag in upload comp : ', this.openUploadWindow);
  }

  // Helper function to extract file extension
  getFileExtension(fileName: string): string {
    return fileName.substring(fileName.lastIndexOf('.'));
  }

  uploadImages() {
    // console.log(' user pressed upload !!!');
    // console.log('compressed selected files to uplaod :', this.selectedFiles);

    if (this.selectedFiles.length === 0) {
      // not closing window if no image is selected.
      console.log('no files selected !');
    } else {
      // uploading photos

      // creating array of file name and file type for generating presigned urls
      let imageFileNames: Array<{ fileName: string; imageType: string }> = [];
      for (let img of this.finalPhotos) {
        imageFileNames.push({ fileName: img.name, imageType: img.type });
      }

      // if ((this.purpose = 'profile')) {
      //   console.log('uploading a profile photo ##');
      //   console.log('imageFileNames array : ',imageFileNames);

      // } else {
      // console.log('imageFileNames array : ',imageFileNames);

      this.milestoneService
        .getPresignedUrl(
          imageFileNames,
          this.catId,
          this.subcatId,
          this.studentId,
          this.dob_date,
          this.dob_month,
          this.dob_year,
          this.purpose
        )
        .subscribe({
          next: async (data: any) => {
            let presignedUrls = data.data;

            // console.log('presigned urls are : ',presignedUrls);

            // now that our presigned urls are ready , finally uploading the images on its respective presigned url
            for (let i = 0; i < presignedUrls.length; i++) {
              // in this for loop , we will upload the image to its presigned url one by one
              try {
                let extension = this.getFileExtension(presignedUrls[i].Name);
                let fileType = extension.slice(1); // removing dot from extension of file.
                // console.log('extension after replacing dot : ',fileType);
                // console.log('file name in presigned is : ',extension);
                const response = await fetch(presignedUrls[i].presignedUrl, {
                  method: 'PUT',
                  headers: {
                    // here as user may uplaod image of any type/extension (jpg,jpeg,png,heic,etc). we can't hardcode the type of image. so making it dynamic.
                    'Content-Type': `image/${fileType}`,
                    'x-amz-acl': 'public-read',
                  },
                  body: this.finalPhotos[i],
                });

                if (response.ok) {
                  // displaying confirmation message to user upon successful upload of photos

                  console.log('photo uplaoded SUCCESSFULLY !!');
                } else {
                  alert('Image upload failed !!');
                }
              } catch (error) {
                alert('Something went wrong');
              }
            } // uploading finished

            // updating the database.
            // inserting imagePaths in database.
            let uploadImagePath: string[] = [];
            for (let img of presignedUrls) {
              let imagePath: string = img.path;

              uploadImagePath.push(imagePath);
            }

            // console.log('uploadImagePath array is :',uploadImagePath);
            this.milestoneService
              .insertImagePath(
                this.studentId,
                this.catId,
                this.subcatId,
                uploadImagePath, // here we're reusing the 'imageFileNames' array to send imagePaths to save some space.
                this.dob_date,
                this.dob_month,
                this.dob_year,
                this.purpose
              )
              .subscribe({
                next: async (data: any) => {
                  // console.log('data from insertImagePath api', data.data);

                  if (data.data.update == 1) {
                    // newly clicked/uploaded photos uploaded successfully
                    this.toastService.showAlert(
                      this.translate.instant('milestone.photosUploaded'),
                      this.translate.instant('Note')
                    );
                    // console.log(
                    //   'images endpoints INSERTED in database SUCCESSFULLY !!'
                    // );
                  } else if (data.data.update == 2) {
                    this.toastService.showAlert(
                      this.translate.instant('milestone.photosUploaded'),
                      this.translate.instant('Note')
                    );
                    // console.log(
                    //   'images endpoints UPDATED in database SUCCESSFULLY !!'
                    // );
                  } else {
                    this.toastService.showAlert(
                      this.translate.instant('milestone.photosConfilct'),
                      this.translate.instant('Note')
                    );
                    // console.log('database NOT updatated $$ ');
                  }
                },
              }); // insertImagePath api end
          },
        }); // getPresignedUrl api end
      // }

      this.openUploadWindow = !this.openUploadWindow; // closing window.
      this.closeUploadWindow.emit(this.openUploadWindow); // sending boolean value to milestone saying that window is closed.
    }
  }
}
