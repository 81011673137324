import { Component, OnInit, inject } from '@angular/core';

import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { Router } from '@angular/router';
import { classRoomService } from '../../../shared/services/api/class-room.service';
import { ResultsService } from '../../../shared/services/api/results.service';
import { CoreUtilityService } from '../service/core-utility.service';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../../shared/pipe/hindi-number.pipe';
import { PushNotificationService } from '../../../shared/services/notifications/push-notification.service';
import { GraphService } from '../../../shared/services/utility/graph.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    NavbarComponent,
    FooterNavbarComponent,
    TranslateModule,
    HindiNumberPipe,
  ],
  providers: [classRoomService, ResultsService],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css',
})
export class DashboardComponent implements OnInit {
  private _classRoomService = inject(classRoomService);
  private router = inject(Router);
  private _resultService = inject(ResultsService);
  private utility = inject(CoreUtilityService);
  private translate = inject(TranslateService);
  private pushService = inject(PushNotificationService);
  private graphService = inject(GraphService);

  teacherName: string = 'Mr. John Doe';
  teacherId: number = 1;
  result: any = [];
  userData: any;
  class_id: number = 1;
  school_id: number = 1;
  loader: boolean = false;
  standard: string = 'Classroom';
  division: string = 'A';
  navbarDisplay: string = 'dashboard';
  classResult: number = 0;
  NoResults: boolean = false;
  otherClassrooms: any;
  createdClassRoom: any;
  endSemDate: any;
  transcription: string = '';
  isRecording: boolean = false;
  term: string = 'Term';
  startDate: any = '';
  endDate: any = '';
  previous_period_name: any = '';
  termProgress: string = '';
  // globalObject$: Observable<TeacherState>;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.userData = window.localStorage.getItem('user');

      this.userData = JSON.parse(this.userData);
      if (this.userData) {
        this.class_id = this.userData.class_id;
        this.school_id = this.userData.school_id;
        this.teacherName = this.userData.teacher_name;
        this.teacherId = this.userData.teacher_id;

        /*
         * 1) subscribeToNotifications :  Below two functions we are using to get notification subscription
         * so that we can get permission to send notificaion to user on browser
         *
         * 2) listenForNotifications : We are using this function which is checking wheter
         * there is any notification or not
         */
        this.pushService.subscribeToNotifications(this.teacherId);
        this.pushService.listenForNotifications();

        this.createdClassRoom = this.utility.getFormattedDate(
          this.userData.class_created
        );
        this.endSemDate = this.utility.getSixMonthLaterDate(
          this.userData.class_created
        );
        const monthAbbreviations = [
          'Jan.',
          'Feb.',
          'Mar.',
          'Apr.',
          'May.',
          'Jun.',
          'Jul.',
          'Aug.',
          'Sep.',
          'Oct.',
          'Nov.',
          'Dec.',
        ];

        this._classRoomService
          .getClassRoomByTeacherID(this.teacherId)
          .subscribe({
            next: res => {
              this.result = res;

              const data : any = this.result.data[0];

              if (data.assessment_period_id) {
                localStorage.setItem('assessmentPeriodId', data.assessment_period_id);
                localStorage.setItem('term','Ongoing');
                this.termProgress = 'Ongoing';
              } 

              if(data.previous_assessment_period_id) {
                localStorage.setItem('assessmentPeriodId', data.previous_assessment_period_id);
                localStorage.setItem('term','Completed');
                this.termProgress = 'Completed';
                this.previous_period_name = data.previous_period_name;
              }
              
              this.class_id = data.class_id;
              this.standard = data.standard;
              this.division = data.division;
              
              // Determine the term name
              this.term = data.period_name || data.previous_period_name;
              
              // Format dates if available
              const startDate = new Date(data.start_date || data.previous_start_date);
              const endDate = new Date(data.end_date || data.previous_end_date);
              
              if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
                this.startDate = `${startDate.getDate()} ${monthAbbreviations[startDate.getMonth()]} ${startDate.getFullYear().toString().slice(-2)}`;
                this.endDate = `${endDate.getDate()} ${monthAbbreviations[endDate.getMonth()]} ${endDate.getFullYear().toString().slice(-2)}`;
              }
              

              this.loader = true;
              console.log(this.loader);
              //creating overall class result piechart
              this.getClassOverallResult();
            },
            error: error => {
              console.error('Error:', error);
            },
          });
      } else {
        this.router.navigate(['/login']);
      }
    }
  }

  goToClassroom(): void {
    this.router.navigate(['/class-room', this.class_id, this.teacherName]);
  }

  goToConnectWithParents() {
    this.router.navigate(['/connect-with-parents', this.class_id]);
  }

  goToStatsResults() {
    this.router.navigate(['/stats-results']);
  }

  createChart(result: number) {
    // Create root and chart
    const root = am5.Root.new('chartdiv');

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        innerRadius: am5.percent(50),
        layout: root.verticalLayout,
      })
    );

    const data = [
      {
        status: 'Done',
        result: result,
        sliceSettings: {
          fill: am5.color(0x00fff0),
          stroke: am5.color(0x000000),
        },
      },
      {
        status: 'NotDone',
        result: 100 - result,
        sliceSettings: {
          fill: am5.color(0xe5e5e5),
          stroke: am5.color(0x000000),
        },
      },
    ];

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'result',
        categoryField: 'status',
        // legendLabelText: "none"
      })
    );

    series.animate({
      key: 'startAngle',
      to: result,
      duration: 3000,
      easing: am5.ease.yoyo(am5.ease.linear),
    });

    series.slices.template.setAll({
      templateField: 'sliceSettings',
      showTooltipOn: 'click',
    });

    series.labels.template.setAll({
      maxWidth: 1,
      oversizedBehavior: 'hide',
    });

    series.ticks.template.set('visible', false);

    series.data.setAll(data);

    series.appear();
    chart.appear();
  }

  getClassOverallResult() {
    this._resultService
      .getClassOverallResult(this.class_id)
      .subscribe((data: any) => {
        console.log(' getClassOverallResult api response : ',data);
        if (data.message !== 'overallResult fetched successfully') {
          this.NoResults = true; // meaning , not a single assessment is attempted from this entire class. results are not there.
          this.classResult = 0;
        } else {
          let sum = 0;
          this.classResult = 0;
          let count = 0;
          for (const obj of data.data) {
            sum += obj.result;
            count++;
          }

          this.classResult = Math.floor((sum / (count * 100)) * 100); // calculating percentage. (count*90) is the max marks . sum is obtained marks.
          console.log("classResult = ", this.classResult);
          this.graphService.createPieChart(
            'chartdiv',
            this.classResult,
            0xa4dded // dashboard pieChart color
          );
          // this.createChart(this.classResult);
        }
      });
  }
}
