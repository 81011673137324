import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../../../config/config';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private http = inject(HttpClient);
  private config = inject(ApplicationConfig);

  // private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // data$ = this.dataSubject.asObservable();

  // setData(data: any): void {
  //   this.dataSubject.next(data);
  // }
  // getStudentData() {
  //   return this.studentData;
  // }

  url :string = 'http://localhost:8080';
  token :string | null = "";

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
      const config = this.config;
 
      this.url =  config.getAPIEnvironment();
  }

  
  // for getting the teacher details for the teacher profile.
  getTeacherInfoById(teacherId: number): Observable<object> {

    if(typeof window !== 'undefined' && window.localStorage){
      this.token = localStorage.getItem('token');
    }

    const reqHeaders : HttpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${this.url}/teacher/teacher-info-by-id/${teacherId}` ,{ headers: reqHeaders });
  }

  getSummaryReportByUserId(user_id: number): Observable<object>{
    if(typeof window !== 'undefined' && window.localStorage){
      this.token = localStorage.getItem('token');
    }

    const reqHeaders : HttpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${this.url}/result/summary-report/${user_id}` ,{ headers: reqHeaders });
  }


  // for getting the parent's current plan details for the summary-report.
  checkParentPlan(userId: number): Observable<any> {

    if(typeof window !== 'undefined' && window.localStorage){
      this.token = localStorage.getItem('token');
    }

    const reqHeaders : HttpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${this.url}/parents/check-parent-plan/${userId}` ,{ headers: reqHeaders });
  }

}
