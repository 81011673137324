import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
interface PushNotification {
  notification: {
    title: string;
    body: string;
  };
}


@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  private VAPID_PUBLIC_KEY = environment.vapidPublicKey;
  private BACKEND_URL = environment.apiUrl;

  constructor(private swPush: SwPush, private http: HttpClient) {}

  // Request Notification Permission and Subscribe to Notifications
  subscribeToNotifications(userId: number) {
    if (!this.swPush.isEnabled) {
      console.error('Push notifications are not enabled in this browser.');
      return;
    }

    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })
    .then(subscription => {
      console.log('Push Subscription:', subscription);
      this.sendSubscriptionToServer(userId, subscription);
    })
    .catch(err => console.error('Could not subscribe to notifications', err));
  }

  // Send Subscription to Backend
  private sendSubscriptionToServer(userId: number, subscription: PushSubscription) {
    const payload = {
      userId: userId,
      subscription: subscription
    };

    this.http.post(`${this.BACKEND_URL}/notifications/subscribe`, payload)
      .subscribe({
        next: () => console.log('Subscription sent to backend successfully'),
        error: (error) => console.error('Failed to send subscription', error)
      });
  }

  listenForNotifications() {
    this.swPush.messages.subscribe((message: object) => {
      console.log('Received a push message:', message);
       let icon = '/assets/icons/icon-72x72.png';
      // Type guard to check if message has a 'notification' property
      if (this.isPushNotification(message)) {
        const { title, body } = message.notification;
  
        // Display the notification
        if (Notification.permission === 'granted') {
          new Notification(title, { body , icon });
        } else {
          console.log('Notifications are not allowed.');
        }
      } else {
        console.log('Message format is not a PushNotification:', message);
      }
    });
  
    this.swPush.notificationClicks.subscribe(({ action, notification }) => {
      console.log('Notification clicked:', notification, action);
      // Handle notification click actions here
    });
  } 

  private isPushNotification(message: any): message is PushNotification {
    return (
      message &&
      typeof message === 'object' &&
      message.notification &&
      typeof message.notification.title === 'string' &&
      typeof message.notification.body === 'string'
    );
  }
  
}
