import { Component,inject } from '@angular/core';
import { ViewImagesService } from '../../services/utility/view-images.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-view-images',
  standalone: true,
  imports: [],
  templateUrl: './view-images.component.html',
  styleUrls: ['./view-images.component.css']
})
export class ViewImagesComponent {
  private viewImageService = inject(ViewImagesService);
  private http = inject(HttpClient);
  
  recievedAllImages : string[] = [];
  currentImageIndex: number = 0;
  showImage : boolean = false;

  touchstartX = 0;
  touchstartY = 0;
  touchendX = 0;
  touchendY = 0;
  
  
  ngOnInit(){
    this.viewImageService.currentImages.subscribe((data=>{
      this.recievedAllImages = data.allImages;
      this.currentImageIndex = data.index;
      this.showImage = data.showImage;
    }));
    

    // console.log("touchable image is: ", this.touchableImage);

  }

  onTouchStart(event: TouchEvent) {
    this.touchstartX = event.changedTouches[0].screenX;
    this.touchstartY = event.changedTouches[0].screenY;
  }

  onTouchEnd(event: TouchEvent) {
    this.touchendX = event.changedTouches[0].screenX;
    this.touchendY = event.changedTouches[0].screenY;
    this.handleGesture();
  }

  handleGesture() {
    // right-left swipes 
    if (this.touchendX < this.touchstartX) {
      // swiped left , hence going to next image
      // console.log('Swiped Left');
      this.nextImage();
    } else {
      // swiped right , hence going to previous image
      // console.log('Swiped Right');
      this.previousImage();
    }

    // up-down swipes
    // if (this.touchendY < this.touchstartY) {
    //   console.log('Swiped Up');
    // } else{
    //   console.log('Swiped Down');
    // }

    // // tap
    // if (this.touchendX === this.touchstartX && this.touchendY === this.touchstartY) {
    //   console.log('Tap');
    // }
  }


  previousImage(){
    // event.stopPropagation(); // Prevent closing the modal when clicking download
    console.log('previous image')
    if(this.currentImageIndex !== 0){
      this.currentImageIndex--;
    }else{
      this.currentImageIndex = this.recievedAllImages.length-1
    }
  }

  nextImage(){
    // event.stopPropagation(); // Prevent closing the modal when clicking download
    console.log('next image')
    if(this.currentImageIndex < this.recievedAllImages.length-1){
      this.currentImageIndex++;
    }else{
      this.currentImageIndex = 0;
    }
  }

  closeImageView(){
    this.showImage = !this.showImage;
  }

downloadImage(url : string) {
  this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
    // Create a URL from the blob
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'private-image.jpg';
    console.log("inside");
    
    // Trigger the download
    link.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
  });
}

  
}
