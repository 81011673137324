import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApplicationConfig } from '../../../config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortfolioPdfDownloadService {
  private config = inject(ApplicationConfig);
  private http = inject(HttpClient);

  url: string = 'http://localhost:8080/v1';
  token: string | null = '';
  reuestHeader: HttpHeaders = new HttpHeaders();
  assessmentPeriodId: number | null = null;

  constructor(...args: unknown[]);
  constructor() {
    const config = this.config;

    this.url = config.getAPIEnvironment();
  }

  setReqHeader() {
    let reqHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (typeof window !== 'undefined' && window.localStorage) {
      this.token = localStorage.getItem('token');
      if (this.token) {
        reqHeaders = reqHeaders.set('Authorization', 'Bearer ' + this.token);
        this.assessmentPeriodId = Number(localStorage.getItem('assessmentPeriodId')) || null;

      }
    }

    return reqHeaders;
  }

  downloadPortfolio(
    classId: number,
    studentId: number,
    dob_month: number,
    dob_day: number,
    dob_year: number,
    targetedLang: string
  ): Observable<Blob> {
    this.reuestHeader = this.setReqHeader();
  
    // Make the GET request to the backend with the necessary parameters
    return this.http.get<Blob>(
      `${this.url}/template/portfolio_template/${classId}/${studentId}/${dob_month}/${dob_day}/${dob_year}/${targetedLang}/${this.assessmentPeriodId}`,
      {
        headers: this.reuestHeader,
        responseType: 'blob' as 'json',  // Important: Specify the response type as 'blob'
      }
    );
  }
  
}
