<app-navbar [navbarDisplay]="navbarDisplay"></app-navbar>

<div class="container w-100 d-flex justify-content-center align-items-center flex-column">
    <div class="profilePhoto mt-3">
        <img src="../../../../assets/img/profile.png" alt="">
    </div>
    <div class="teacherName professional-blue-font fs-1 mt-2 ps-3 pe-3 d-flex justify-content-center">
        <span>{{teacherName}}</span>
    </div>
    <div class="teacher-info black-font p-3 mt-3 fs-5 fw-semibold d-flex  flex-column">
        <span>Teacher ID: {{teacherCode}}</span>
        <span>Email : {{teacherEmail}}</span>
        <span>Contact no. : {{teacherContact}}</span>
        <span>Gender : {{teacherGender}}</span>
    </div>
</div>


