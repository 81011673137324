<app-navbar [navbarDisplay]="navbarDisplay"></app-navbar>
<div class="confirmationPage">
<div class="container black-font">
    <div class="message">
        <p>{{childName}}{{'prentInvite.message' | translate}}</p>
    </div>

    <div class="information-card">
        <h4>{{'summaryReport.parentDetails' | translate}}</h4>
        <div class="fields">
            <p>{{'summaryReport.name' | translate}} {{parentName}}</p>
            <p NgIf="parentContactStatus">{{'summaryReport.contact' | translate}} + {{parentContact}}</p>
            <p>{{'summaryReport.email' | translate}} {{parentEmail}}</p>
        </div>
    </div>


    <div class="information-card">
        <h4>{{'prentInvite.childDetails' | translate}}</h4>
        <div class="fields">
            <p>{{'summaryReport.name' | translate}} {{childName}}</p>
            <p>{{'prentInvite.birthDate' | translate}} : {{childDob}}</p>
            <p>{{'prentInvite.gender' | translate}} : {{childGender}}</p>
        </div>
    </div>


    <div class="buttons">
        <button class="backButton" (click)="goback()" >{{'cancel' | translate}}</button>
        <button class="backButton" (click)="confirm()">{{'confirm' | translate}}</button>
    </div>
</div>
</div>


<app-footer-navbar [class_id]="class_id"></app-footer-navbar>