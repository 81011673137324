import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../../../shared/component/navbar/navbar.component';
import { FooterNavbarComponent } from '../../../shared/component/footer-navbar/footer-navbar.component';
import { NamesectionComponent } from '../../../shared/component/nameSection/namesection.component';
import { ActivatedRoute } from '@angular/router';
import { ProgressTrackerService } from '../../../shared/services/api/progress-tracker.service';

/* Imports */
import am5index from '@amcharts/amcharts5/index';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import { count } from 'node:console';
import { AnyARecord } from 'node:dns';
import { Obj } from '@popperjs/core';


// pipe Imports
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HindiNumberPipe } from '../../../shared/pipe/hindi-number.pipe';
import { SpinnerComponent } from '../../../shared/component/spinner/spinner.component';


interface subcatResults {
  subcatId: number;
  subcatName: string;
  [key: string]: string | number; // Add an index signature for dynamic properties
}

interface mainchartResultData {
  catShortName: string;
  catName: string;
  [key: string]: string | number; // Add an index signature for dynamic properties
}

@Component({
  selector: 'app-progress-tracker',
  standalone: true,
  imports: [
    CommonModule,
    NavbarComponent,
    FooterNavbarComponent,
    NamesectionComponent,
    HindiNumberPipe,
    TranslateModule
  ],
  providers: [ProgressTrackerService],
  templateUrl: './progress-tracker.component.html',
  styleUrl: './progress-tracker.component.css',
})
export class ProgressTrackerComponent {
  private route = inject(ActivatedRoute);
  private progressService = inject(ProgressTrackerService);
  private translate  = inject(TranslateService);

  navbarDisplay: string = 'none';

  class_id: number = 0;
  student_id: number = 0;
  studentName: string = '';
  age: string = '';
  params: any;
  results: any;
  profilePhoto: string = '';
  isCategoriesExpaneded: boolean = false;
  categoryWise: string = 'Select Category';

  // chart variables
  private root: am5.Root | undefined;
  chart: any;
  wholeData: any;
  chartType: string = 'mainChart';
  subcatResults: subcatResults[] = [];

  mainChartData: mainchartResultData[] = [
    {
      catShortName: 'PD',
      catName: this.translate.instant('Physical Development'),
      // sem1: 0,
      // sem2: 0,
      // sem3: 0,
    },
    {
      catShortName: 'CL',
      catName: this.translate.instant('Communication and Language'),
      // sem1: 0,
      // sem2: 0,
      // sem3: 0,
    },
    {
      catShortName: 'SD',
      catName: this.translate.instant('Socio-Emotional Development'),
      // sem1: 0,
      // sem2: 0,
      // sem3: 0,
    },
    {
      catShortName: 'PS',
      catName: this.translate.instant('Problem Solving'),
      // sem1: 0,
      // sem2: 0,
      // sem3: 0,
    },
    {
      catShortName: 'GK',
      catName: this.translate.instant('General Knowledge'),
      // sem1: 0,
      // sem2: 0,
      // sem3: 0,
    },
    {
      catShortName: 'AC',
      catName: this.translate.instant('Arts and Creativity'),
      // sem1: 0,
      // sem2: 0,
      // sem3: 0,
    },
  ];

  constructor() {
    this.params = this.route.snapshot.queryParams;
    this.class_id = Number(this.route.snapshot.paramMap.get('class_id'));
    this.student_id = Number(this.route.snapshot.paramMap.get('student_id'));
    this.studentName = this.params.studentName;
    this.age = this.params.age;
    this.profilePhoto = this.params.profilePhoto;

    // console.log('class id in progress tracker : ', this.class_id);
    // console.log('student_ id : ', this.student_id);
    // console.log('params in progress tracker : ', this.params);
  }

  ngOnInit() {
    // console.log('ON init start !');
    this.getProgressResults();
  }

  getProgressResults() {
    // console.log('inside getProgressResults !');
    this.progressService
      .getProgressTrackerResults(this.student_id)
      .subscribe((data: any) => {
        console.log('whole data ', data.data); // last record in data.data is the latest age-group result of the student.
        this.wholeData = data.data;

        // getting the agegroups
        const ageGroups = Object.keys(this.wholeData);
        // console.log('agegroups : ', ageGroups);

        // mainChartData must have as many `semX` keys as age-groups results recieved in response of api
        data.data = this.mainChartData.map(data => {
          const updatedData: any = { ...data }; // Using `any` to dynamically add keys
          ageGroups.forEach((ageGroup, index) => {
            const semKey = `sem${index + 1}`; // Generate dynamic sem keys
            if (!(semKey in updatedData)) {
              updatedData[semKey] = 0; // initialize with 0 if not already present
            }
          });
          return updatedData;
        });

        // setting the data from api into the mainChartData array by which we will create chart.
        ageGroups.forEach((ageGroup, index) => {
          const semKey = `sem${index + 1}`; // Determine the corresponding sem key
          // console.log('semKey : ', semKey);
          // console.log('data. data after semkey : ', data.data);

          // Check if the age group data exists
          if (this.wholeData[ageGroup]) {
            this.wholeData[ageGroup].forEach(
              (categoryData: {
                shortCatName: string;
                catName: string;
                cat_avg: number;
              }) => {
                // console.log(
                //   'categoryData in 2nd forEach: ',
                //   categoryData
                // );
                const { shortCatName, cat_avg } = categoryData;

                // Find the matching catShortName in mainChartData
                const matchingCategory = this.mainChartData.find(
                  data => data.catShortName === shortCatName
                );

                // Assign the cat_avg value to the appropriate sem key
                if (matchingCategory) {
                  matchingCategory[semKey] = cat_avg;
                }
              }
            );
          } else {
            console.warn(`AgeGroup ${ageGroup} not found in API response.`);
          }
        });

        console.log(
          'final mainchardata before creating chart: ',
          this.mainChartData
        );
        this.createChart(this.mainChartData, this.chartType);
      }); // subscribe end
  }

  toggleDropdown() {
    this.isCategoriesExpaneded = !this.isCategoriesExpaneded;
  }

  categoryTracker(selectedCategory: any) {
    this.categoryWise = selectedCategory.catName;
    this.chartType = 'categoryChart';
    // console.log('chartType',this.chartType);
    this.isCategoriesExpaneded = !this.isCategoriesExpaneded;

    // console.log('category selected : ', selectedCategory);
    const ageGroups = Object.keys(this.wholeData);
    // console.log('ageGroups in catTracker : ', ageGroups);

    // -----------------------------------------------------------------------

    ageGroups.forEach((ageGroup, index) => {
      const semKey = `sem${index + 1}`; // Determine the sem key dynamically
      // console.log('semkey : ', semKey);

      this.wholeData[ageGroup].forEach((category: any) => {
        // console.log('category :  ', category);
        if (category.shortCatName === selectedCategory.catShortName) {
          if (category.subcat_results) {
            // Iterate over subcat_results for the current age group
            // console.log(
            //   'category.subcat_results : ',
            //   category.subcat_results,
            //   ageGroup
            // );

            category.subcat_results.forEach(
              (subcat: {
                subcatId: number;
                subcatName: string;
                result: number;
              }) => {
                // console.log('subcat : ', subcat);
                const { subcatId, subcatName, result } = subcat;
                // console.log("subcat : ",  subcatId, subcatName , result);
                let existingSubcat = this.subcatResults.find(
                  subcategory => subcategory.subcatId === subcatId
                );
                if (!existingSubcat) {
                  // If the subcategory doesn't exist, create a new object
                  existingSubcat = {
                    subcatId,
                    subcatName,
                  };
                  // console.log("existingSubcat",existingSubcat)
                  this.subcatResults.push(existingSubcat);
                }

                // Add or update the semKey with the result value
                existingSubcat[semKey] = result;
              }
            );
          } else {
            console.warn(`No subcat_results found for AgeGroup ${ageGroup}`);
          }
        } else {
          console.warn('No matching category found');
        }
      });
    });

    // -----------------------------------------------------------------------

    // console.log('categoryChart Data  ', this.subcatResults);
    this.createChart(this.subcatResults, this.chartType);

    // emptying subcatResults so user can select other categories too. if didn't , the subcats of new category which user selects will be appended on the old catgory's subcats.
    this.subcatResults = [];
    // console.log('categoryChart Data AFTER Empty $$$: ', this.subcatResults);
  }

  createChart(chartData: Object[], chartType: string) {
    console.log('data in createChart : ', chartData);
    console.log('chartType in create chart : ', chartType);

    // disposing previous root for the categoryChart
    if (this.root) {
      // only disposing if previous exists.
      if (this.root.dom.id === 'categoryChart') {
        console.log(' disposing previous root');
        this.root.dispose();
      }
    }

    this.root =
      chartType === 'mainChart'
        ? am5.Root.new('mainChart')
        : am5.Root.new('categoryChart');

    this.root.setThemes([am5themes_Animated.new(this.root)]);

    if (this.root._logo) {
      this.root._logo.dispose();
    }

    let chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panX: false, // graph zoom
        panY: false, // graph zoom
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: this.root.verticalLayout,
        pinchZoomX: true,
      })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(this.root, {
      minorGridEnabled: true,
    });
    xRenderer.grid.template.set('location', 0);
    xRenderer.labels.template.setAll({
      location: 0.5,
      multiLocation: 0.5,
    });

    // setting data on xAxis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField:
          chartType === 'mainChart' ? 'catShortName' : 'subcatName',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
        snapTooltip: true,
      })
    );

    xRenderer.labels.template.setAll({
      oversizedBehavior: 'truncate',
      textAlign: 'center',
    });

    xAxis.data.setAll(chartData);

    // testing
    xAxis.get('renderer').set('minGridDistance', 5); // Adjust the value as needed (default is 50)

    // Rotating the x axis subcat names so they don't overlap on each other
    if (chartType !== 'mainChart') {
      xAxis.get('renderer').labels.template.setAll({
        rotation: -30,
        centerY: am5.p100,
        centerX: am5.p50,
      });
    }

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(this.root, {
          inversed: false,
        }),
      })
    );






    // Adding cursor
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(this.root, {
        alwaysShow: false,
        xAxis: xAxis,
        // positionX: undefined,
      })
    );

    cursor.lineY.set('visible', false);
    cursor.lineX.set('focusable', false);





    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    function createSeries(
      root: am5.Root,
      name: string,
      field: string,
      color: string
    ) {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField:
            chartType === 'mainChart' ? 'catShortName' : 'subcatName',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText:
              chartType === 'mainChart'
                ? '[bold]{name}[/]\n{catName}: {valueY}'
                : '[bold]{name}[/]\n{subcatName}: {valueY}',
          }),
        })
      );

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 5,
            fill: series.get('fill'),
          }),
        });
      });

      // create hover state for series and for mainContainer, so that when series is hovered,
      // the state would be passed down to the strokes which are in mainContainer.
      series.set('setStateOnChildren', true);
      series.states.create('hover', {});

      series.mainContainer.set('setStateOnChildren', true);
      series.mainContainer.states.create('hover', {});

      series.strokes.template.states.create('hover', {
        strokeWidth: 4,
      });

      // console.log('chartData inside createseries: ',chartData);
      series.data.setAll(chartData);

      series.appear(1000);
      series.set('stroke', am5.color(color)); // line color
      series.set('fill', am5.color(color)); // point color

    }

    const colors: string[] = [
      '#D9B061',
      '#557373',
      '#90A869',
      '#D88B79',
      '#E589AF',
      '#8E89E5',
      '#79C7DC',
      '#A28078',
      '#99C1B9',
    ];

    // creating series for chart
    // if (chartType === 'mainChart') {
    let noOfSem = Object.keys(chartData[0]).length - 2; // noOfSem means number of semesters, considering a single object. -2 is for not considering "catShortName" and "catName" field in semester count.
    // console.log('number of semesters =  ', noOfSem);

    // creating the series dynamically using loop
    for (let i = 0; i < noOfSem; i++) {
      let seriesLabel = `Sem ${i + 1}`;
      let semester = `sem${i + 1}`;
      let color;
      if (colors.length === i) {
        // preventing array out of bounds error.
        color = colors[i - 1];
      } else {
        color = colors[i];
      }
      createSeries(this.root, seriesLabel, semester, color);
    }
    // }
    // else {
    //   let noOfSem = Object.keys(chartData[0]).length - 2; // noOfSem means number of semesters, considering a single object. -2 is for not considering "subcatId" and "subcatName" field in semester count.
    //   console.log('number of semesters in createSeries =  ', noOfSem);

    //   // creating the series dynamically using loop
    //   for (let i = 0; i < noOfSem; i++) {
    //     let seriesLabel = `Sem ${i + 1}`;
    //     let semester = `sem${i + 1}`;
    //     let color;
    //     if (colors.length === i) {
    //       // preventing array out of bounds error.
    //       color = colors[i - 1];
    //     } else {
    //       color = colors[i];
    //     }
    //     createSeries(seriesLabel, semester, color);
    //   }
    // }

    // databar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/

    // zoom-in zoom-out scroll bar
    // chart.set(
    //   'scrollbarX',
    //   am5.Scrollbar.new(this.root, {
    //     orientation: 'horizontal',
    //     width : 260,
    //     marginBottom: 20,
    //   })
    // );

    // legend is nothing but index for the graph
    let legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.percent(0),
        x: am5.percent(5),
        useDefaultMarker: true,
        marginTop: 10,
      })
    );

    // Make series change state when legend item is hovered
    legend.itemContainers.template.states.create('hover', {});

    // setting legends / Index
    legend.data.setAll(chart.series.values); // default index

    legend.markers.template.setAll({
      width: 25,
      height: 18,
    });
    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 5, // TL = top left
      cornerRadiusTR: 5,
      cornerRadiusBL: 5, // BL = bottom left
      cornerRadiusBR: 5,
    });





    // chart.plotContainer.events.on('pointerout', function () {
    //   cursor.set('positionX', 1);
    // });

    chart.plotContainer.events.on('pointerover', function () {
      cursor.set('positionX', undefined);
    });




    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
  }
}
