@if(loader == true) {
<app-navbar [navbarDisplay]="navbarDisplay" [class_id]="class_id" [goBackTo]="goBackTo"></app-navbar>
<div class="parent-container black-font">
  <app-namesection [studentName]="studentName" [profilePhoto]="profilePhoto" [age]="age" class="studentName"></app-namesection>

  <ul class="tabs">
    <li class="tab"><button class="tabBtn black-font" id="summaryBtn" [ngClass]=" {'activeTab': mode === 'summary'} "
        (click)="selectTab('summary')">{{'summaryReport.sReport' | translate}}</button></li>
    <li class="tab"><button class="tabBtn black-font" id="collatedBtn" [ngClass]=" {'activeTab': mode === 'collated'} "
        (click)="selectTab('collated')">{{'summaryReport.cReport' | translate}}</button></li>
  </ul>
  @if(mode === 'summary') {
  <!-- summary report end -->

  <div class="summaryTab flex-cent flex-col">
    <h2 class="header-text">{{'summaryReport.summaryFrom' | translate}}</h2>

    <!-- parent details section. only visible in SAM's summary report and not in parents app -->
    <div class="parent-details-container black-font">
      <h5 style="
          margin-top: 0.4rem;
          font-family: var(--font-proxima-bold);
        ">
        {{'summaryReport.parentDetails' | translate}}
      </h5>
      <div class="details">
        <p>{{'summaryReport.name' | translate}} {{ parent_first_name }} {{ parent_last_name }}</p>
        <p>{{'summaryReport.email' | translate}} {{ parent_email }}</p>
        <p>{{'summaryReport.contact' | translate}} {{ parent_contact }}</p>
      </div>
    </div>

    <!-- basic profile section -->
    @if(user_plan === 'basic') {
    <div class="basicProfile ">
      <!-- <h5 class="under-development">Summary report for basic customers is under development</h5> -->
      <div class="categories-section">
        <!-- <h5>{{ 'summaryReport.basciStats' | translate}}</h5> -->
        <div class="categories ">
          <div class="category-card basicCard professional-blue-font" (click)="goToSubcatSummary('Basic', basicParentAverage)">
            <h5 class="catName">
              {{'basic' | translate}}
            </h5>
            <div class="cat-details">
              <div class="home-activities">
                <h6 style="font-size: 0.8rem">{{'summaryReport.customized' |
                  translate}}<br />{{'summaryReport.recommendations' | translate}}</h6>
                <p>{{'total' | translate}} : {{basicTotal | hindiNumber}}</p>
                <p>{{'done' | translate}} : {{basicDone | hindiNumber}}</p>
              </div>

              <div class="average">
                @if(basicParentAverage === 0){
                <h5 style="margin: 0">
                  {{'pending' | translate}}
                </h5>
                }@else if(basicParentAverage !==0 && basicParentAverage <= 25){ <h5>{{ 'summaryReport.Emerging' |
                  translate}}</h5>
                  }@else if(basicParentAverage >=26 && basicParentAverage <=66){ <h5>{{ 'summaryReport.Developing' |
                    translate}}</h5>
                    }@else{
                    <h5>{{ 'summaryReport.Proficient' | translate}}</h5>
                    }


                    <p class="average-score">
                      {{ basicParentAverage | hindiNumber }}%
                    </p>
                    @if(basicParentAverage !== 0){
                    <p class="more-datails">
                      {{'moreDetails' | translate}} >>
                    </p>
                    }

              </div>

              <div class="chartDiv">
                <div id="basicParentPie" class="piechart"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    }@else {
    <div class="premium" #premium>
      <!-- bargraph section  -->
      <div class="bargraph-section">
        <!-- <h5>{{ 'summaryReport.overallStats' | translate}}</h5> -->
        <div id='createBargraph'></div>
      </div>

      <!-- categories section  -->
      <div class="categories-section professional-blue-font">
        <!-- <h5 style="margin-top: 1rem; margin-bottom: 0">{{'summaryReport.catWiseStats' | translate}}</h5> -->
        @for (obj of CategoryResult; track obj.cat_id) {
        <div class="categories">
          <div class="category-card" [ngClass]="{
                  lightYellow: obj.parentResult >= 26 && obj.parentResult <= 65,
                  lightRed: obj.parentResult <= 25,
                  lightGreen: obj.parentResult >= 66
                }" (click)="goToSubcatSummary(obj.cat_name, obj.parentResult)">
            <h5 class="catName">
              {{ obj.cat_name | translate }}
            </h5>
            <div class="cat-details">
              <div class="home-activities">
                <p>{{'summaryReport.customized' | translate}}<br />{{'summaryReport.recommendations' | translate}}</p>
                <p>{{'total' | translate}} : {{ obj.total | hindiNumber}}</p>
                <p>{{'done' | translate}} : {{ obj.done | hindiNumber }}</p>
              </div>

              <div class="average">
                @if(obj.parentResult === 0) {
                <h5 style="margin: 0">
                  {{'pending' | translate}}
                </h5>
                }@else if(obj.parentResult !==0 && obj.parentResult <= 25){ <h5>{{ 'summaryReport.Emerging' |
                  translate}}</h5>
                  }@else if(obj.parentResult >=26 && obj.parentResult <=66){ <h5>{{ 'summaryReport.Developing' |
                    translate}}</h5>
                    }@else{
                    <h5>{{ 'summaryReport.Proficient' | translate}}</h5>
                    }


                    <p class="average-score">{{ obj.parentResult | hindiNumber }}%</p>
                    @if(obj.parentResult !== 0) {
                    <p class="more-datails">
                      {{'moreDetails' | translate}} >>
                    </p>
                    }

              </div>

              <div class="chartDiv">
                <div id="{{ obj.parentChartId }}" class="piechart"></div>
              </div>
            </div>
          </div>
        </div>
        }

      </div>
    </div>
    }

  </div>
  } @else {


  <!-- collated results start -->

  <div class="collatedTab flex-cent flex-col">
    <h3 class="header-text">{{'summaryReport.collatedFrom' | translate}} </h3>

    <!-- basic parent collated-->
    @if(user_plan === 'basic') {
    <div class="basicProfile basicCollatedCard">
      <!-- <h5 class="under-development">Summary report for basic customers is under development</h5> -->
      <div class="categories-section">
        <!-- <h5>{{ 'summaryReport.basciStats' | translate}}</h5> -->
        <div class="categories professional-blue-font">
          <div class="category-card basicCard"
            (click)="goToSubcatCollated('Basic', 0, basicCollatedAverage , basicParentAverage , basicTeacherAverage)">
            <h5 class="catName">
              {{'basic' | translate}}
            </h5>
            <div class="cat-details">
              <div class="teacherResult">
                <h6>{{ 'summaryReport.parentsResults' | translate}} <br> {{basicParentAverage | hindiNumber}}%</h6>
                <div class="chartDiv">
                  <div id="basicParentPie" class="piechart"></div>
                </div>
              </div>

              <div class="average">
                @if(basicParentAverage === 0) {
                <h5 style="margin: 0">
                  {{'pending' | translate}}
                </h5>
                }@else if(basicParentAverage !==0 && basicParentAverage <= 25){ <h5>{{ 'summaryReport.Emerging' |
                  translate}}</h5>
                  }@else if(basicParentAverage >=26 && basicParentAverage <=66){ <h5>{{ 'summaryReport.Developing' |
                    translate}}</h5>
                    }@else{
                    <h5>{{ 'summaryReport.Proficient' | translate}}</h5>
                    }

                    <p class="average-score">
                      {{ basicCollatedAverage | hindiNumber }}%
                    </p>
                    @if( basicParentAverage !== 0 && basicTeacherAverage !==0) {
                    <p class="more-datails">
                      {{'moreDetails' | translate}} >>
                    </p>
                    }

              </div>

              <div class="teacherResult">
                <h6>{{'summaryReport.teacherResults' | translate}} <br> {{basicTeacherAverage | hindiNumber}}%</h6>
                <div class="chartDiv">
                  <div id="basicTeacherPie" class="piechart"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    } @else {
    <!-- premium parents collated -->
    <!-- categories section  -->
    <div class="categories-section">
      <!-- <h5 style="margin-top: 1rem; margin-bottom: 0">Category-wise Stats</h5> -->
      @for (obj of CategoryResult; track obj.cat_id) {
      <div class="categories professional-blue-font">
        <div class="category-card" [ngClass]="{
                lightYellow: obj.averageResult >= 26 && obj.averageResult <= 65 && obj.parentResult !=0 && obj.teacherResult !=0,
                lightRed: obj.averageResult <= 25 && obj.averageResult > 0 || obj.parentResult == 0 || obj.teacherResult == 0,
                lightGreen: obj.averageResult >= 66 && obj.parentResult !=0 && obj.teacherResult !=0,
                lightGray: obj.averageResult == 0 
              }"
          (click)="goToSubcatCollated(obj.cat_name, obj.cat_id, obj.averageResult , obj.parentResult , obj.teacherResult)">
          <h5 class="catName">
            {{ obj.cat_name | translate }}
          </h5>
          <div class="cat-details">
            <div class="parentResult flex-cent flex-col">
              <h6>{{'summaryReport.parentsResults' | translate}} <br> {{obj.parentResult | hindiNumber}}%</h6>
              <div class="chartDiv">
                <div id="{{ obj.parentChartId }}" class="piechart"></div>
              </div>
            </div>

            <div class="average flex-cent flex-col">
              @if(obj.parentResult === 0 || obj.teacherResult === 0) {
              <h5 style="margin: 0">
                {{'pending' | translate}}
              </h5>
              }@else if(obj.averageResult >= 1 && obj.averageResult <= 25){ <h5>{{ 'summaryReport.Emerging' |
                translate}}</h5>
                }@else if(obj.averageResult >= 26 && obj.averageResult <=66 ){ <h5>{{ 'summaryReport.Developing' |
                  translate}}</h5>
                  }@else{
                  <h5>{{ 'summaryReport.Proficient' | translate}}</h5>
                  }


                  <p class="average-score">{{ obj.averageResult | hindiNumber }}%</p>
                  @if(obj.parentResult !== 0 && obj.teacherResult !== 0) {
                  <p class="more-datails">
                    {{'moreDetails' | translate}} >>
                  </p>
                  }

            </div>

            <div class="teacherResult flex-cent flex-col">
              <h6>{{'summaryReport.teacherResults' | translate}} <br> {{obj.teacherResult | hindiNumber}}%</h6>
              <div class="chartDiv">
                <div id="{{ obj.teacherChartId }}" class="piechart"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }

    </div>
    }
  </div>
  }
</div>

<app-footer-navbar [class_id]="class_id"></app-footer-navbar>
}@else {
<app-spinner></app-spinner>
}