import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApplicationConfig } from '../../../config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressTrackerService {

  private http = inject(HttpClient);
  private config = inject(ApplicationConfig);

  url: string = 'http://localhost:8080/v1';
  token: string | null = "";
  reuestHeader: HttpHeaders = new HttpHeaders;
  constructor() {
      const config = this.config;
      this.url = config.getAPIEnvironment();
   }

   setReqHeader() {
    let reqHeaders: HttpHeaders = new HttpHeaders({
       'Content-Type': 'application/json'
     });
 
     if (typeof window !== 'undefined' && window.localStorage) {
       this.token = localStorage.getItem('token');
       if (this.token) {
         reqHeaders = reqHeaders.set('Authorization', 'Bearer ' + this.token);
       }
     }
 
     return reqHeaders;
   }

  getProgressTrackerResults( student_id: number):Observable<Object>{
    this.reuestHeader = this.setReqHeader();
    return this.http.get<any>(`${this.url}/tracker/get-progress-report-results/${student_id}`, {headers : this.reuestHeader});
  }
}
