

<section class="nameSection black-font" [ngClass]="{'studentName': milestonePage}">
    <div class="profilePic_Name d-flex align-items-center">
        @if(!profilePhoto ){
            <img class="profilePhoto" src="../../../../assets/img/profile.png" alt="No Image">
        }@else{
            <img class="profilePhoto" [src]="profileImageLink + profilePhoto" alt="">
        }
        <span class="name">{{ studentName }}</span>
    </div>
    <span class="age">{{ age }}</span>
</section>
