@if (loader) {
  <div class="spinner">
    <app-navbar [teacherName]="teacherName" [navbarDisplay] = "navbarDisplay" [goBackTo]="goBackTo"></app-navbar>
    <div class="cwp-page">
      <h2 class="text-center black-font parent-connect-heading">{{'ParentsConnect.title' | translate}}</h2>
      <section class="container">
        @for (obj of child_parent_details; track obj; let i = $index) {
          <div
            [ngClass]="{'card-light-yellow':i%2===0 , 'card-dark-yellow': i%2!==0 }">
            <div class="parent-card black-font">
              <div class="logoName">
                @if(obj.profile_image){
                  <img [src]="profileImageLink + obj.profile_image" alt="">
                }@else{
                  <img src="../../../../assets/img/profile.png" alt="childPic">
                }
                
                <div class="names">
                  <span class="parentName">{{obj.parent_name}} {{obj.parent_last_name}}</span>
                  <span class="childName">{{obj.child_name}} {{obj.child_last_name}}</span>
                </div>
              </div>
              <button class="invite-button" (click)="onInviteClick(obj.child_id, obj.school_connect_status, obj.parent_id)"
                [ngClass]="getButtonClass(obj.school_connect_status)"
                [disabled]="isInviteDisabled(obj.school_connect_status)">
                {{ getButtonText(obj.school_connect_status) | translate }}
              </button>
            </div>
          </div>
        }
      </section>
    </div>
    <app-footer-navbar [class_id]="class_id"></app-footer-navbar>
  </div>
} @else {
  <app-spinner></app-spinner>
}
