<div class=" position-sticky top-0 header w-100">
  @if (schoolNavBar) {
    <div class="topSection d-flex">
      <div class="backArrow">
        @if(!noBack){
          <img src="../../../../assets/img/back_arrow.png" class="back_arrow_img" alt="" (click)="goBack()" role="presentation">
        }
      </div>
 
      <div class="navContent d-flex" >  
        <img [src]="schoolLogo" class="schoolLogo" alt="" srcset="">
        <div class=" navRight d-flex justify-content-center align-items-center flex-column">
          <p class="mt-2 mb-1 fs-4 text-wrap schoolName">{{ schoolName }}</p>
          <!-- <p class="p-0 mt-0 fs-4 text-wrap">Welcome, {{ teacherName }}</p> -->
          <div class="companyLogo d-flex justify-content-center align-items-center">
            <span>{{ 'poweredBy' | translate}}</span>
            <img src="../../../../assets/img/BMC_R_Logo.png" alt="BMCLogo">
          </div>
        </div>
      </div>
    </div>
  }


  <!-- defualt lower navbar  -->
  @if (navbarDisplay === 'show') {
    <div class="classDetails black-font d-flex justify-content-around align-items-center mb-2">
      <span>{{'welcome' | translate}},<br> {{ teacherName }} </span>
      @if (!(standard === '' || totalStudents === 0)) {
        <div
          class="class d-flex justify-content-around align-items-center flex-column">
          <span>{{'class' | translate}}: {{ standard }} - {{division}}</span>
          <span>{{'totalStudents' | translate}}: {{totalStudents | hindiNumber}}</span>
        </div>
      }
    </div>
  }

  <!-- lower navbar only for dashboard component -->
  @if (navbarDisplay === 'dashboard' ) {
    <div
      class="classDetails-dashboard black-font d-flex justify-content-around align-items-center mb-2">
      <span>{{'welcome' | translate}}, {{ teacherName }} </span>
    </div>
  }

  <!-- lower navbar only for teacherProfile component -->
  @if (navbarDisplay === 'teacherProfile') {
    <div
      class="classDetails-myprofile black-font">
      <p class="your-profile">{{'yourProfile' | translate}}</p>
    </div>
  }

  <!-- lower navbar only for Invite-Confirm component -->
  @if (navbarDisplay === 'inviteConfirm') {
    <div
      class="classDetails-myprofile black-font d-flex justify-content-around align-items-center mb-2">
      <span>{{'parentVerification' | translate}}</span>
    </div>
  }

</div>